
import { defineComponent, onMounted } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as Yup from "yup";
import firebase from 'firebase'
import { useToast } from "vue-toastification";

export default defineComponent({
  name: "account-settings",
  components: {
    ErrorMessage,
    Field,
    Form,
  },
  data(){
    return {
      user: null,
      sure: false,
      loading: false
    }
  },
  methods: {
    async getuser(){
      try{
        let { data } = await this.axios.get(`/users/${this.$route.params.uid}`)
        console.log(data.user)
        this.user = data.user
      }catch(e){
        console.log(e)
      }
    },
    async deleteUser(){

      if(!this.sure){
        Swal.fire({
          text: 'Please check the confirm box to proceed with account deletion.',
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try Again",
          customClass: {
              confirmButton: "btn fw-bold btn-light-danger",
          },
        })
        return
      }

      try{
        this.loading = true
        let res = await this.axios.delete(`/users/${this.$route.params.uid}`)
        
        this.loading = false
        if(res.data.success){
          Swal.fire({
            text: res.data.message,
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Alright",
            customClass: {
                confirmButton: "btn fw-bold btn-light-primary",
            },
          })
          setTimeout(()=>this.$router.push('/users'), 3000)
        }else{
          Swal.fire({
            text: res.data.message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try Again",
            customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
            },
          })
        }
      }catch(e){
        this.loading = false
        Swal.fire({
            text: e,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Try Again",
            customClass: {
                confirmButton: "btn fw-bold btn-light-danger",
            },
        })
      }
    },
    async saveChanges(){
      try{
        this.loading = true
        let res = await this.axios.put(`/users/${this.$route.params.uid}`, {
          surname: this.user.surname,
          name: this.user.firstname,
          email: this.user.email,
          phone: this.user.phones[0],
          username: this.user.username
        })

        this.loading = false
        if(res.data.success){
          useToast().success('The account has been updated successfully.')
          this.$router.replace(this.$router.currentRoute.value)
        }else{
          useToast().error(res.data.message)
        }
      }catch(e){
        this.loading = false
        
        try{
          useToast().error(e.response.data.message)
        }catch(err){
          useToast().error(e.message)
        }
      }
    }
  },
  mounted(){
    this.getuser()
  }
});
